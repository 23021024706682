<template>
  <div class="detail">
    <logo/>

    <b-card no-body class="main-content">
      <b-card-header><div class="editable float-left stroke chainling-font text-white"><span>{{ chainling ? chainling.nickname : "..."}}</span> <font-awesome-icon icon="fa-edit" class="edit-icon" style="color:black" v-b-modal.modal-prevent-closing></font-awesome-icon></div><div class="float-right"><b-spinner class="text-primary" small v-if="transactionInProgress"></b-spinner></div></b-card-header>
      <b-card-body>
        <loader v-if="!editionInfo.ready"></loader>

        <div v-else class="row">
          <div class="col-md-6 py-3">

            <img v-if="chainling.image" class="chainling-img" :src="chainling.image"/>
          </div>
          <div class="col-md-6">
            <b-tabs fill pills content-class="mt-3">
              <b-tab title="Attributes" active>
                <div class="px-md-3 chainling-font text-white">
                  <div class="row">
                    <div class="col-6"><b>ID</b></div><div class="col-6" style="text-align: right">{{id}}</div>
                  </div>
                  <div class="row" v-for="(trait, key) in chainling.attributes" :key="key">
                    <div class="col-6"><b>{{ trait.trait_type }}</b></div>
                    <div class="col-6" style="text-align: right">{{ trait.value }}</div>
                  </div>
                </div>
              </b-tab>
              <!--              <b-tab title="Info">-->
              <!--                <p> info goes here </p>-->
              <!--              </b-tab>-->
              <b-tab title="Actions">
                <b-form-group class="chainling-font text-white">
<!--                  <b-form-checkbox :disabled="transactionInProgress" size="lg" v-model="stakedToggler" v-on:change="toggleStaking" name="check-button" switch>-->
                  <b-form-checkbox disabled="true" size="lg" v-model="stakedToggler" v-on:change="toggleStaking" name="check-button" switch>
                    Stake
                  </b-form-checkbox>
                  <b v-if="chainling.rewards"><br/>Pending Rewards: {{ chainling.rewards }} ฿LING</b>
                  <hr>
                  <b-button disabled>Sell</b-button>
                </b-form-group>

              </b-tab>
            </b-tabs>


          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Set nickname"
        header-bg-variant="primary"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleSubmit"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Name"
            label-for="name-input"
            invalid-feedback="Name is required"
            :state="nameState"
        >
          <b-form-input
              id="name-input"
              v-model="chainling.nickname"
              :state="nameState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  computed: {
    ...mapGetters({
      account: 'Web3/account',
    }),
    ...mapState({
      editionInfo(state) {
        return state.Chainlings[this.edition]
      },
      chainling(state) {
        const chainlings = state.Chainlings[this.edition]
        return chainlings.owned[this.id] || chainlings.staked[this.id]
      }
    })
  },
  props: [
    "id", "edition"
  ],
  data() {
    return {
      stakedToggler: false,
      contractAddress: "",
      ready: false,
      transactionInProgress: false
    }
  },
  watch: {
       async chainling() {
         await this.primeStakingToggler()
      }
  },
  async beforeMount() {
    await this.primeStakingToggler()
  },
  methods: {
    async primeStakingToggler() {
      const edition = this.$chainlings.Editions[this.edition]
      this.stakedToggler = await edition.isTokenStaked(this.id)
      this.contractAddress = await edition.contractAddress
    },
    async toggleStaking() {
      const edition = this.$chainlings.Editions[this.edition]
      this.transactionInProgress = true;
      try {

        if (this.stakedToggler === true) {
          await edition.stake(this.id);
        } else {
          await edition.unstake(this.id);
        }

      } catch (e) {
        this.stakedToggler = !this.staked;
        await this.transactionFailed(e)
      }
      this.transactionInProgress = false;
    },

    async handleSubmit() {
      const edition = this.$chainlings.Editions[this.edition]
      this.transactionInProgress = true;
      try {
          const r = await edition.setNickname(this.id, this.chainling.nickname);
          await r.wait();
      } catch (e) {
        this.chainling.nickname = await edition.getNickname(this.id)
        await this.transactionFailed(e);
      }


      this.transactionInProgress = false;

      this.$refs['modal'].hide();

    }
  }
}
</script>

<style lang="scss" scoped>

.editable {
  .edit-icon {
    display: none;
  }

  &:hover {
    .edit-icon {
      cursor: pointer;
      display: initial;
    }
  }

}

.tab-content {
  height: 10rem;
  overflow: scroll;
}

ul.traits {
  list-style: none;
  margin: 0;
  padding: 0;

}
</style>
